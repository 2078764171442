import gql from 'graphql-tag';

export const paginationFragment = gql`
  fragment PaginationFragment on PaginationV2 {
    limit
    offset
    total
  }
`;

export const userFragment = gql`
  fragment UserFragment on SummaryUserV2 {
    id
    firstName
    middleName
    lastName
    email
    initialsAndLastName
    fullName
    accountName
  }
`;
export const opportunityUserFragment = gql`
  fragment OpportunityUserFragment on OpportunityUserV2 {
    id
    firstName
    middleName
    lastName
    email
    initialsAndLastName
    fullName
    username
  }
`;

/** Use this to fetch for pentair users e.g people with @pentair in their emails */
export const projectOpportunityPentairUsersFragment = gql`
  ${opportunityUserFragment}
  ${opportunityUserFragment}

  fragment ProjectOpportunityPentairUsersFragment on OpportunityV2 {
    account {
      email
      id
      name
      owner {
        name
        email
      }
      shippingCountryCode
    }
    createdBy {
      ...OpportunityUserFragment
    }
    owner {
      ...OpportunityUserFragment
    }
    amount
    closeDate
    country
    createdAt
    currency
    financialRegion
    id
    multiBidParent
    name
    opportunityNumber
    probability
    recordType
    stage
  }
`;

/** Use this to fetch project opportunity for normal users */
export const projectOpportunityFragment = gql`
  ${opportunityUserFragment}
  ${opportunityUserFragment}

  fragment ProjectOpportunityFragment on OpportunityV2 {
    account {
      email
      id
      name
      owner {
        name
        email
      }
      shippingCountryCode
    }
    createdBy {
      ...OpportunityUserFragment
    }
    owner {
      ...OpportunityUserFragment
    }
    amount
    closeDate
    country
    createdAt
    currency
    financialRegion
    id
    name
    opportunityNumber
    recordType
    multiBidParent
    stage
    probability
  }
`;

/** Use this to fetch project opportunity for normal users */
export const listProjectsOpportunityFragment = gql`
  fragment ListProjectsOpportunityFragment on OpportunityV2 {
    opportunityNumber
    multiBidParent
    stage
    probability
    account {
      name
    }
  }
`;

export const projectFragment = gql`
  ${userFragment}

  fragment ProjectFragment on Project {
    id
    name
    destination
    updatedAt
    createdAt
    updatedBy {
      ...UserFragment
    }
    createdBy {
      ...UserFragment
    }
    accountManager {
      ...UserFragment
    }
    users {
      ...UserFragment
    }
    solutions {
      id
      name
    }
    opportunity {
      id
      name
      opportunityNumber
      stage
      probability
      account {
        name
        id
        owner {
          name
          email
        }
        shippingCountryCode
      }
    }

    awaitingReview
    awaitingOpportunity
  }
`;

export const projectFragmentFetch = gql`
  ${userFragment}
  fragment ProjectFragmentFetch on Project {
    id
    name
    destination
    updatedAt
    createdAt
    updatedBy {
      ...UserFragment
    }
    createdBy {
      ...UserFragment
    }
    accountManager {
      ...UserFragment
    }
    users {
      ...UserFragment
    }
    solutions {
      id
      name
    }
    remarks {
      createdAt
      createdBy {
        ...UserFragment
      }
      text
    }
    awaitingReview
    awaitingOpportunity
  }
`;

export const proposalsFragment = gql`
  fragment ProposalsFragment on ProposalV2 {
    id
    name
    solutionId
    status
  }
`;
